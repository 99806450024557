jQuery(document).ready(function($) {
	

	function addSpanToFirstWord( obj ) {
		obj.each(function(){
		     var me = obj;
		     me.html(me.html().replace(/^(\w+)/, '<span class="first">$1</span>'));
		});
	}

	var heroTitle = $("#hero-title");
	var philTitle = $(".philosophy").find(".section-title");
	var coreTitle = $("#core-services").find(".section-title");
	var whyTitle = $(".why-us").find(".section-title");
	var consultancyTitle = $("#consultancy").find(".section-title");

	addSpanToFirstWord( heroTitle );
	addSpanToFirstWord( philTitle );
	addSpanToFirstWord( coreTitle );
	addSpanToFirstWord( whyTitle );
	addSpanToFirstWord( consultancyTitle );

	$('.testimonials__slider').owlCarousel({
	    loop:true,
	    margin:10,
	    nav:true,
	    items: 1,
	    dots: true,
	    navText : ["",""],
	});

	var parentMenu = $(".menu-item-has-children");
	parentMenu.find("a:first").append("<span class='expand-sub'></span>");

	parentMenu.on('click', function() {
		$(this).toggleClass('sub-open');
	})

	if( parentMenu.hasClass('current-menu-parent') ) {
		// console.log('open');
		parentMenu = $(".menu-item-has-children.current-menu-parent");
		parentMenu.addClass('sub-open');
	}

	$(".expand-sub").on("click", function(e) {
		e.preventDefault();
		e.stopPropagation();

		var parent = $(this).parents(".menu-item-has-children");

		if( !parent.hasClass("sub-open") ) {
			parent.addClass("sub-open");
		} else {
			parent.removeClass("sub-open");
		}
	});

	var dummyLink = $(".menu-item-has-children").find("a[href*=#]");

	dummyLink.on('click', function() {
		var parent = $(this).parents(".menu-item-has-children");

		if( !parent.hasClass("sub-open") ) {
			parent.addClass("sub-open");
		} else {
			parent.removeClass("sub-open");
		}
	})

	$('.nav-toggle').on('click', function() {
		$(this).parents('.fixed-sidebar').toggleClass('open');
	});

	$('#why-us-slider').owlCarousel({
	    loop:true,
	    margin:20,
	    nav:true,
	    items: 4,
	    dots: false,
	    navText : ["",""],
	    responsive:{
	            0:{
	                items:1,
	                nav:true
	            },
	            768:{
	                items:2,
	                nav:true
	            },
	            1200:{
	                items:4,
	                nav:true
	            }
	        }
	});

	headerCheck();

	$(window).resize( function() {
		headerCheck();
	});

	function headerCheck() {
		var winWidth = $(window).innerWidth();
		var tel = $("#header-phone");
		var button = $("#header-contact");

		if( winWidth >= 768 ) {
			tel.css("display", "none");
			button.css("display", "block");
		} else {
			tel.css("display", "block");
			button.css("display", "none");
		}
	}

	var navParent = $("#menu-main-menu").find(".menu-item-has-children");
	if( navParent.hasClass("current_page_parent") ){
		navParent = $("#menu-main-menu").find(".menu-item-has-children.current_page_parent");
		navParent.each(function(){
			$(this).addClass("sub-open");
		})
	}


	//Profile Accordion
	var accordion = $('.accordion');

	accordion.find('.headers').on('click', function() {
		
		var parent = $(this).parents('.accordion');
		parent.toggleClass('open');

		$('.accordion').not(parent).removeClass('open');



	});

}); 
